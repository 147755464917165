import React from 'react'

const Nfts = () => {
    return (
        <div id="nfts" className="nfts">
            <nft-card
            //001_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924345336842158081">
            </nft-card>
            <br/>
            <nft-card
            //002_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924346436353785857">
            </nft-card>
            <br/>
            <nft-card
            //003_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924347535865413633">
            </nft-card>
            <br/>
            <nft-card
            //004_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924348635377041409">
            </nft-card>
            <br/>
            <nft-card
            //005_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924350834400296961">
            </nft-card>
            <br/>
            <nft-card
            //006_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924351933911924737">
            </nft-card>
            <br/>
            <nft-card
            //007_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924353033423552513">
            </nft-card>
            <br/>
            <nft-card
            //008_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924354132935180289">
            </nft-card>
            <br/>
            <nft-card
            //009_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924355232446808065">
            </nft-card>
            <br/>
            <nft-card
            //010_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924356331958435841">
            </nft-card>
            <br/>
            <nft-card
            //011_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924357431470063617">
            </nft-card>
            <br/>
            <nft-card
            //012_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924358530981691393">
            </nft-card>
            <br/>
            <nft-card
            //013_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924359630493319169">
            </nft-card>
            <br/>
            <nft-card
            //014_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924360730004946945">
            </nft-card>
            <br/>
            <nft-card
            //015_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924361829516574721">
            </nft-card>
            <br/>
            <nft-card
            //016_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924362929028202497">
            </nft-card>
            <br/>
            <nft-card
            //017_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924364028539830273">
            </nft-card>
            <br/>
            <nft-card
            //018_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924365128051458049">
            </nft-card>
            <br/>
            <nft-card
            //019_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924366227563085825">
            </nft-card>
            <br/>
            <nft-card
            //020_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924367327074713601">
            </nft-card>
            <br/>
            <nft-card
            //021_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924368426586341377">
            </nft-card>
            <br/>
            <nft-card
            //022_#Pterodactylus
            contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
            tokenId="25617208838104636599641664777183010851146760914569639463943924370625609596929">
            </nft-card>
            <br/>
        </div>
    )
}

export default Nfts
