import React from 'react'
import Unity, { UnityContext } from "react-unity-webgl";
import './LevelDesigns.scss';

const unityContext = new UnityContext({
    loaderUrl: "./LevelDesigns/Build/LevelDesigns.loader.js",
    dataUrl: "./LevelDesigns/Build/LevelDesigns.data",
    frameworkUrl: "./LevelDesigns/Build/LevelDesigns.framework.js",
    codeUrl: "./LevelDesigns/Build/LevelDesigns.wasm",
  });

const LevelDesigns = () => {
    return (
        <div id="LevelDesigns" className="LevelDesigns">
            
            <div className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <div className="box">
                                <h3>First_#1</h3>
                                <p>Click clock to enter</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="box">
                                <h3>Second_#2</h3>
                                <p>A S W D keys to move and attack</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="box">
                                <h3>Third_#3</h3>
                                <p>Hold down Spacebar to Fly!</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="box">
                                <h3>Fourth_#4</h3>
                                <p>Left mouse click to fire...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Unity 
      unityContext={unityContext} 
      style={{
        width: "800px",
        height: "400px",
        border: "2px solid black",
        background: "black",
        position: "relative",
        margin: "3rem",
      }}
      />
        </div>
        
    )
}

export default LevelDesigns
