import React from 'react'
import { About, Footer, Header, Skills, Youtube, Nfts, LevelDesigns, Work } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => {
  return (
    
    <div className='app'>
    
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Youtube className='app__youtube' videoId='-t8vX66wLHw'/>
      <Nfts/>
      <LevelDesigns/>
      {/* <Testimonial /> */}
      <Footer />
    </div>
  )
}

export default App