import React from 'react';
import YouTube from 'react-youtube';
import './Youtube.scss';

class Example extends YouTube {
    
  
    VideoOnReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo()
    }
      
  render() {
      const {videoId} = this.props
      
      return <YouTube  videoId={videoId}  onReady={this._VideoOnReady} />;

      
    }


}
export default YouTube