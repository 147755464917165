import React from 'react'
import { BsTwitter, BsInstagram, BsGithub, BsDiscord } from 'react-icons/bs';
import {FaFacebookF } from 'react-icons/fa';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon,
  GithubShareButton,
  GithubIcon,
} from "react-share";

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            {/* <BsTwitter />  */}
            <TwitterShareButton
                                url={"https://twitter.com/Khristophe_Keen"}
                                quote={"Khristophe Keen"}
                                hashtag="#unity"
                            >
                                <TwitterIcon className="mx-3" size={36}/>
                            </TwitterShareButton>
                             </div>
                             <div>
                             <LinkedinShareButton
                                url={"https://www.linkedin.com/in/khristophe-keen-46270394/"}
                                quote={"Khristophe Keen"}
                                hashtag="#unity"
                            >
                                <LinkedinIcon className="mx-3" size={36}/>
                            </LinkedinShareButton>
                             </div>
                             <div>
                             <FacebookShareButton
                                url={"https://www.facebook.com/profile.php?id=100071738678790"}
                                quote={"Khristophe Keen"}
                                hashtag="#unity"
                            >
                                <FacebookIcon className="mx-3" size={36}/>
                            </FacebookShareButton>
                             </div>
                             <div>
                             <RedditShareButton
                                url={"https://www.reddit.com/user/KhristopheKeen"}
                                quote={"Khristophe Keen"}
                                hashtag="#unity"
                            >
                                <RedditIcon className="mx-3" size={36}/>
                            </RedditShareButton>
                             </div>
                             
        {/* <div>
            <BsGithub />
        </div>
        <div>
            <BsDiscord />
        </div> */}
    </div>
  )
}

export default SocialMedia